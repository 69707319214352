import React from 'react';

const Blog = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <h1 className="text-4xl font-bold text-center font-poppins">
        Writing coming soon! (I promise)
      </h1>
    </div>
  );
};

export default Blog;

