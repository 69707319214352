import React from 'react';

const TimelineItem = ({ date, content, index }) => (
  <div className="relative pl-8 pb-8 last:pb-0 before:absolute before:left-0 before:top-2 before:w-3 before:h-3 before:bg-black dark:before:bg-white before:rounded-full before:-translate-x-1/2 before:z-10 md:before:left-1/2 md:pl-0 md:pb-0 md:my-8">
    {/* Line connecting dots */}
    <div className="absolute left-0 top-2 bottom-0 w-0.5 bg-black dark:bg-white -translate-x-1/2 md:left-1/2">
      {!content.includes('Present') && <div className="h-full" />}
    </div>
    
    {/* Content container with alternating sides on desktop */}
    <div className={`md:w-5/12 ${index % 2 === 0 ? 'md:mr-auto md:pr-8' : 'md:ml-auto md:pl-8'}`}>
      <div className={`bg-white dark:bg-gray-800 p-4 rounded shadow border border-gray-200 dark:border-gray-700`}>
        <div className="font-bold mb-2">{date}</div>
        {content}
      </div>
    </div>
  </div>
);

const Work = () => {
  const experiences = [
    { date: "June 2023 - Present", content: "Cofounder and CTO of Contour (YC S23)" },
    { date: "October - March 2023", content: "Data Analyst at Rocketship.VC" },
    { date: "October - July 2022", content: "Software Engineer at Hypeshot" },
    { date: "June - September 2021", content: "Software Engineer (Part-time) at Hypeshot" },
    { date: "June - September 2020", content: "Software Engineering Intern at Amazon Web Services" },
    { date: "January - March 2020", content: "AI Research Fellow at UnifyID" },
    { date: "June - September 2019", content: "Machine Learning Intern at Polarr" },
    { date: "June - August 2018", content: "Software Engineering Intern at AmerisourceBergen Co. (now Cencora)" },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Work Experience</h1>
      <div className="relative">
        {experiences.map((exp, index) => (
          <TimelineItem
            key={`${exp.date}-${index}`}
            date={exp.date}
            content={exp.content}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};

export default Work;
