import React from 'react';
import Navbar from './Navbar';

const Layout = ({ children }) => {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <div className="flex flex-col md:flex-row">
        <Navbar />
        <main className="flex-1 p-4 md:p-8 mt-16 md:mt-0 md:ml-[250px]">
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
