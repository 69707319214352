import React from 'react';
import { FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

const Home = () => {
  return (
    <div className="flex flex-col items-start justify-center h-full px-4 md:px-0">
      <h1 className="text-4xl md:text-6xl font-bold mb-4">Hi, I'm Vamsi</h1>
      <p className="text-lg md:text-xl text-gray-600 dark:text-gray-300 mb-8 max-w-2xl">
        I'm the cofounder and CTO of{' '}
        <a 
          href="https://www.trycontour.com" 
          target="_blank" 
          rel="noopener noreferrer"
          className="text-black dark:text-white font-semibold hover:underline"
        >
          Contour (YC S23)
        </a>
        . I enjoy reading, coding, chess, and make questionable bets on football and basketball.
      </p>
      <p className="text-lg md:text-xl text-gray-600 dark:text-gray-300 mb-8 max-w-2xl">
        Stanford CS/Math - BS '21/MS '22 
      </p>
      <div className="flex space-x-6">
        <a href="https://x.com/vam_saladi" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-500 transition-colors">
          <FaTwitter size={28} />
        </a>
        <a href="https://www.linkedin.com/in/vamsisaladi99/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-700 transition-colors">
          <FaLinkedin size={28} />
        </a>
        <a href="https://www.instagram.com/vamsizzle/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-pink-600 transition-colors">
          <FaInstagram size={28} />
        </a>
      </div>
    </div>
  );
};

export default Home;
