import React from 'react';
import { FaStar } from 'react-icons/fa';
import { FaGoodreads } from 'react-icons/fa';
import { currentlyReading, recentlyRead } from '../data/books';

const BookPanelWithHover = ({ title, author, coverImage, progress, rating, showRating, goodreadsUrl }) => (
  <div className="relative group">
    <a 
      href={goodreadsUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="block md:hidden w-full h-full"
    >
      <div className="w-full h-48 md:h-60 rounded-lg shadow-lg overflow-hidden">
        <img 
          src={coverImage} 
          alt={title}
          className="w-full h-full object-cover"
        />
      </div>
    </a>
    
    <div className="hidden md:block w-full h-48 md:h-60 rounded-lg shadow-lg transition-transform duration-300 group-hover:scale-105 overflow-hidden">
      <img 
        src={coverImage} 
        alt={title}
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-black bg-opacity-75 text-white p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col justify-center items-center">
        <a 
          href={goodreadsUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="text-center hover:underline"
        >
          <p className="font-semibold text-sm mb-1">{title}</p>
          <p className="text-xs mb-4">{author}</p>
        </a>
        {showRating ? (
          <div className="flex space-x-1">
            {[...Array(5)].map((_, i) => (
              <FaStar 
                key={i} 
                className={i < rating ? "text-yellow-400" : "text-gray-600"} 
                size={16}
              />
            ))}
          </div>
        ) : (
          <div className="w-full h-1.5 bg-gray-600 rounded-full overflow-hidden">
            <div 
              className="h-full bg-green-400 transition-all duration-300 rounded-full"
              style={{ width: `${progress}%` }}
            />
          </div>
        )}
      </div>
    </div>
  </div>
);

const Bookshelf = () => {
  return (
    <div className="flex flex-col h-full px-4 md:px-6">
      {/* Mobile-only Goodreads link */}
      <div className="md:hidden mb-8">
        <a 
          href="https://www.goodreads.com/user/show/125421519-vamsi-saladi" 
          target="_blank" 
          rel="noopener noreferrer"
          className="flex items-center gap-2 text-gray-600 hover:text-black dark:text-gray-400 dark:hover:text-white transition-colors"
        >
          <FaGoodreads size={20} />
          <span className="text-sm">Follow me on Goodreads</span>
        </a>
      </div>

      <section className="mb-12">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-2xl font-bold">Currently Reading</h2>
          {/* Desktop-only Goodreads link */}
          <div className="hidden md:block">
            <a 
              href="https://www.goodreads.com/user/show/125421519-vamsi-saladi" 
              target="_blank" 
              rel="noopener noreferrer"
              className="flex items-center gap-2 text-gray-600 hover:text-black dark:text-gray-400 dark:hover:text-white transition-colors"
            >
              <FaGoodreads size={20} />
              <span className="text-sm">Follow me on Goodreads</span>
            </a>
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 md:gap-6">
          {currentlyReading.map((book) => (
            <div key={book.id}>
              <BookPanelWithHover {...book} showRating={false} />
            </div>
          ))}
        </div>
      </section>

      <section>
        <h2 className="text-2xl font-bold mb-4">Recently Read</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 md:gap-6">
          {recentlyRead.slice(0, 6).map((book) => (
            <div key={book.id}>
              <BookPanelWithHover {...book} showRating={true} />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Bookshelf;
